import React from 'react';
import { Link } from 'react-router-dom';
import LoginForm from '../LoginForm';

import AuthCardLayout from 'layouts/AuthCardLayout';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const Login = ({ t, i18n }) => {
  return (
    <AuthCardLayout
      leftSideContent={
        <p className="text-white">
          Don't have an account?
          <br />
          <Link
            className="text-white text-decoration-underline"
            to="/authentication/card/register"
          >
            Get started!
          </Link>
        </p>
      }
    >
      <h3>Account Login</h3>
      <LoginForm layout="card" hasLabel t={t} i18n={i18n} />
    </AuthCardLayout>
  );
};

Login.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired
};

export default withTranslation(['authentication', 'common'])(Login);
