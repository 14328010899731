import React from "react";
import App from "App";
import paths, { rootPaths } from "./paths";
import { createBrowserRouter, Navigate } from "react-router-dom";

import MainLayout from "../layouts/MainLayout";
import ErrorLayout from "../layouts/ErrorLayout";
import Lms from "pages/dashboards/lms";

import Error404 from "pages/errors/Error404";
import Error500 from "pages/errors/Error500";

import CardLogin from "pages/authentication/card/Login";
import CardLogout from "pages/authentication/card/Logout";
import CardRegistration from "pages/authentication/card/Registration";
import CardForgetPassword from "pages/authentication/card/ForgetPassword";
import CardConfirmMail from "pages/authentication/card/ConfirmMail";
import CardPasswordReset from "pages/authentication/card/PasswordReset";
import CardLockScreen from "pages/authentication/card/LockScreen";
import Courses from "../pages/e-learning/course/Courses";
import CourseDetails from "../pages/e-learning/course/course-details";
import CreateCourse from "../pages/e-learning/course/create-a-course";
import StudentOverview from "../pages/e-learning/student-overview";
import LandingLayout from "../layouts/LandingLayout";
import Landing from "../pages/landing/Landing";
import Settings from "../pages/account/profile/Settings";
import UserList from "../pages/account/users/UserList";
import Groups from "../pages/account/groups/Groups";
import UserAddEdit from "../pages/account/users/UserAddEdit/UserAddEdit";
import GroupAddEdit from "../pages/account/groups/GroupAddEdit/GroupAddEdit";
import Profile from "../pages/account/users/UserDetail/Profile";
import Teachers from "../pages/teacher/Teachers";
import TrainerAddEdit from "../pages/teacher/TrainerAddEdit/TrainerAddEdit";
import TrainerProfile from "../pages/teacher/TrainerProfile/TrainerProfile";

const routes = [
  {
    element: <App />,
    children: [
      {
        path: rootPaths.errorsRoot,
        element: <ErrorLayout />,
        children: [
          {
            path: paths.error404,
            element: <Error404 />,
          },
          {
            path: paths.error500,
            element: <Error500 />,
          },
        ],
      },
      {
        path: rootPaths.authRoot,
        children: [
          {
            path: rootPaths.authCardRoot,
            children: [
              {
                path: paths.cardLogin,
                element: <CardLogin />,
              },
              {
                path: paths.cardRegister,
                element: <CardRegistration />,
              },
              {
                path: paths.cardLogout,
                element: <CardLogout />,
              },
              {
                path: paths.cardForgotPassword,
                element: <CardForgetPassword />,
              },
              {
                path: paths.cardResetPassword,
                element: <CardPasswordReset />,
              },
              {
                path: paths.cardConfirmMail,
                element: <CardConfirmMail />,
              },
              {
                path: paths.cardLockScreen,
                element: <CardLockScreen />,
              },
            ],
          },
        ],
      },
      {
        path: rootPaths.root,
        element: <LandingLayout />,
        children: [
          {
            index: true,
            element: <Landing />,
          },
        ],
      },
      {
        path: rootPaths.adminRoot,
        element: <MainLayout />,
        children: [
          {
            path: rootPaths.dashboardRoot,
            children: [
              {
                path: paths.lms,
                element: <Lms />,
              },
            ],
          },
          {
            path: rootPaths.accountRoot,
            children: [
              {
                path: rootPaths.userRoot,
                children: [
                  {
                    index: true,
                    element: <UserList />,
                  },
                  {
                    path: paths.userCreate,
                    element: <UserAddEdit />,
                  },
                  {
                    path: paths.userEdit(":id"),
                    element: <UserAddEdit />,
                  },
                  {
                    path: paths.userDetail(":id"),
                    element: <Profile />,
                  },
                ],
              },
              {
                path: rootPaths.groupRoot,
                children: [
                  {
                    index: true,
                    element: <Groups />,
                  },
                  {
                    path: paths.groupCreate,
                    element: <GroupAddEdit />,
                  },
                  {
                    path: paths.groupEdit(":id"),
                    element: <GroupAddEdit />,
                  },
                ],
              },
              {
                path: paths.userProfile,
                element: <Settings />,
              },
            ],
          },
          {
            path: rootPaths.coursesRoot,
            children: [
              {
                path: paths.courses(":courseLayout"),
                element: <Courses />,
              },
              {
                path: paths.courseDetails(),
                element: <CourseDetails />,
              },
              {
                path: paths.createCourse,
                element: <CreateCourse />,
              },
              {
                path: paths.courseDetails(":courseId"),
                element: <CourseDetails />,
              },
              {
                path: paths.studentOverview,
                element: <StudentOverview />,
              },
            ],
          },
          {
            path: rootPaths.teacherRoot,
            children: [
              {
                index: true,
                element: <Teachers />,
              },
              {
                path: paths.teacherCreate,
                element: <TrainerAddEdit />,
              },
              {
                path: paths.teacherEdit(":id"),
                element: <TrainerAddEdit />,
              },
              {
                path: paths.teacherProfile(":id"),
                element: <TrainerProfile />,
              },
            ],
          },
        ],
      },
      {
        path: "*",
        element: <Navigate to={paths.error404} replace />,
      },
    ],
  },
];

export const router = createBrowserRouter(routes, {
  basename: process.env.PUBLIC_URL,
});

export default routes;
