import React, { useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import CourseCoverPhoto from "./CourseCoverPhoto";
import CourseGoals from "./CourseGoals";
import CourseInformationForm from "./CourseInformationForm";
import CoursePreviewVideo from "./CoursePreviewVideo";
import CoursePricing from "./CoursePricing";
import CourseRequirements from "./CourseRequirements";
import CourseResources from "./CourseResources";
import CreateCourseHeader from "./CreateCourseHeader";
import PublishCourse from "./PublishCourse";
import ScheduleDiscountModal from "./ScheduleDiscountModal";
import { Formik } from "formik";
import Flex from "../../../../components/common/Flex";

const CreateCourse = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    title: "",
    excerpt: "",
    price: "",
    old_price: "",
    category: "",
    subcategory: "",
    condition: "",
    privacy: "",
    visibility: "",
    publish: "",
    trainer: "",
    subject: "",
    tags: [],
    thumbnail: {
      image: null,
      video: null,
      poster: null,
    },
    attachment: {
      image: null,
      src: null,
    },
    features: [
      {
        title: "",
        description: "",
        icon: "",
      },
    ],
    contents: [
      {
        content: "",
      },
    ],
    lessons: [],
    requirements: [
      {
        text: "",
        obligations: [
          {
            obligation: "",
          },
        ],
      },
    ],
  });
  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);

  return loading ? (
    <Flex justifyContent="center" className="p-2 mb-2">
      <Spinner animation={"border"} variant={"primary"} />
    </Flex>
  ) : (
    <Formik initialValues={formData} onSubmit={(values) => console.log(values)}>
      {({ values, setFieldValue }) => (
        <Form encType="multipart/form-data">
          <Row className="g-lg-3 font-sans-serif">
            <Col lg={8}>
              <CreateCourseHeader />
              <CourseInformationForm
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
              />
              <CourseResources
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                index={formData.lessons.length + 1}
              />
              <CourseRequirements />
              <CourseGoals />
            </Col>
            <Col lg={4}>
              <div className="sticky-sidebar top-navbar-height d-flex flex-column">
                <PublishCourse />
                <CourseCoverPhoto />
                <CoursePreviewVideo />
                <CoursePricing
                  isOpenScheduleModal={isOpenScheduleModal}
                  setIsOpenScheduleModal={setIsOpenScheduleModal}
                />
              </div>
            </Col>
          </Row>
          <ScheduleDiscountModal
            isOpenScheduleModal={isOpenScheduleModal}
            setIsOpenScheduleModal={setIsOpenScheduleModal}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CreateCourse;
