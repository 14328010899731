import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import CardDropdown from "components/common/CardDropdown";
import CSRFToken from "../../../../helpers/CSRFToken";
import TinymceEditor from "../../../../components/common/TinymceEditor";

const CourseResources = ({ values, setFieldValue, errors, index }) => {
  const [resourceModal, setResourceModal] = useState(false);
  const [lessonData, setLessonData] = useState({
    id: index,
    title: "",
    image: null,
    excerpt: "",
    is_locked: false,
    is_fulfilled: false,
  });

  const handleFieldChange = (e) => {
    setLessonData({ ...lessonData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    setFieldValue(`lessons.${index}`, lessonData);
    setResourceModal(!resourceModal);
  };

  return (
    <Card className="mb-3">
      <Card.Header as="h5">Create Resources</Card.Header>
      <Card.Body className="p-0">
        <div className="table-responsive scrollbar">
          <Table borderless className="fs-10 text-end mb-0">
            <tbody>
              {values?.lessons?.map((resource, index) => (
                <tr
                  key={index}
                  className={`btn-reveal-trigger ${
                    index % 2 === 0 && "bg-body-tertiary"
                  }`}
                >
                  <td className="align-middle white-space-nowrap text-start">
                    <Flex
                      alignItems="center"
                      className="position-relative gap-3"
                    >
                      <Image
                        src={resource?.image}
                        width={60}
                        className="rounded-1 border border-200"
                      />
                      <div>
                        <h5 className="fs-9 text-primary">
                          {resource?.title}
                          <OverlayTrigger
                            overlay={
                              <Tooltip
                                style={{ position: "fixed" }}
                                id="courseResourceTooltip"
                              >
                                {resource?.is_fulfilled
                                  ? "Requirement fulfilled"
                                  : "No. of Slide/Test doesn't fulfill requirement"}
                              </Tooltip>
                            }
                          >
                            <span className="ms-2 text-primary fs-9">
                              <FontAwesomeIcon
                                icon={
                                  resource?.is_fulfilled
                                    ? "check-circle"
                                    : "exclamation-circle"
                                }
                              />
                            </span>
                          </OverlayTrigger>
                        </h5>
                        <p
                          className="fs-10 text-900 mb-0"
                          dangerouslySetInnerHTML={{
                            __html: resource?.excerpt,
                          }}
                        ></p>
                      </div>
                    </Flex>
                  </td>
                  <td className="align-middle white-space-nowrap fs-10 text-700 text-start">
                    <p className="mb-1">
                      {resource?.details?.slides} Slide •{" "}
                      {resource?.details?.minutes} Minutes
                    </p>
                    <p className="mb-0">{resource?.details?.tests} Tests</p>
                  </td>
                  <td className="align-middle white-space-nowrap text-end">
                    <CardDropdown />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Card.Body>
      <Card.Footer className="text-center py-2">
        <Button
          variant="link"
          size="sm"
          onClick={() => setResourceModal(!resourceModal)}
        >
          <FontAwesomeIcon icon="plus" className="me-1 fs-11" />
          Add New Chapter
        </Button>
      </Card.Footer>
      <Modal
        show={resourceModal}
        onHide={() => setResourceModal(!resourceModal)}
        size={"lg"}
        centered
      >
        <Modal.Header closeButton>
          <h5>Add New Chapter</h5>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <CSRFToken />
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>
                    Title <span className={"text-danger"}>*</span>
                  </Form.Label>
                  <Form.Control
                    type={"text"}
                    value={lessonData.title}
                    name={"title"}
                    onChange={handleFieldChange}
                    placeholder={"Title"}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    Image <span className={"text-danger"}>*</span>
                  </Form.Label>
                  <Form.Control
                    type={"file"}
                    name={"image"}
                    onChange={(e) => {
                      setLessonData({
                        ...lessonData,
                        image: e.target.files[0],
                      });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>
                    Description<span className="text-danger">*</span>
                  </Form.Label>
                  <div className="create-course-description-textarea">
                    <TinymceEditor
                      height="13.438rem"
                      name={"excerpt"}
                      handleChange={(newValue) => {
                        setLessonData({ ...lessonData, excerpt: newValue });
                      }}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Switch
                    className={"mt-3"}
                    label={"Locked"}
                    name={"is_locked"}
                    checked={lessonData.is_locked}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Switch
                    className={"mt-3"}
                    label={"Fulfilled"}
                    checked={lessonData.is_fulfilled}
                    name={"is_fulfilled"}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setResourceModal(!resourceModal)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save <FontAwesomeIcon icon="paper-plane" className="ms-2" />
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default CourseResources;
